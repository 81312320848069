<template>
  <el-dialog
    v-if="poll"
    center
    @close="closeModal()"
    :visible="visible">
    <h3 style="text-align: center">{{ poll.title }}</h3>

    <div class="searchable-container">
      <div class="question-row row" v-for="(question,questionIndex) in poll.backend_questions"
           v-bind:key="questionIndex">
        <div class="col-12">
          <label>{{ question.text }}</label>
        </div>
        <div class="col-12" v-for="(answer,answerIndex) in question.back_answers" v-bind:key="answerIndex">
          <p>{{ answer.text }}</p>
          <div class="row">
            <fg-input type="number"
                      min="0"
                      :key="questionIndex + '-' +answerIndex"
                      :label="'Change voters count'"
                      v-model="answer.edited_counter"
                      @keyup="updatePercentage(question)"
                      @change="updatePercentage(question)">
            </fg-input>
          </div>

          <div class="row counter-container">
            <p class="box-counter badge">real counter: {{ answer.real_counter }}</p>
            <p class="box-counter badge">real percentage: {{ answer.real_percentage + '%' }}</p>
            <p class="box-counter edited-counter badge">edited counter: {{ answer.edited_counter }}</p>
            <p class="box-counter edited-counter badge">edited percentage: {{ answer.edited_percentage + '%' }}</p>
          </div>

        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'edit-percentage-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    poll: {},
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    updatePercentage: function (question) {
      let newEditedCounter = question.back_answers.reduce((sum, item) => this.parseInteger(sum) + this.parseInteger(item.edited_counter), 0);
      for(let answer of question.back_answers ){
        answer.edited_percentage = newEditedCounter > 0 ? this.parseFloat(answer.edited_counter / newEditedCounter * 100) : 0;
      }
    },
    parseInteger(value){
      if (value && value !== ''){
        return parseInt(value);
      }
      return 0;
    },
    parseFloat(value){
      if (value && value !== ''){
        return Math.round(value * 100) / 100
      }
      return 0;
    },
    closeModal() {
      this.$emit('close');
    },
    confirmModal() {
      this.$emit('updatePercentage', this.poll);
    },
  }

}
</script>

<style>
.question-row {
  display: flex;
  align-items: center;
}

.question-row {
  padding: 10px 0px;
  border-bottom: 1px solid;
}

.counter-container {
  display: flex;
}

.counter-container p {
  flex-basis: 24%;
  padding: 10px;
}

.box-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #17a2b8;
  margin-left: 4px;
}

.edited-counter {
  background-color: #d1811c !important;
}
</style>
