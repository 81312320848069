<template>
  <div :class="filter.class">
    <div class="form-group has-label select-filter" >
      <label class="my-2">{{filter.label}} </label>
      <el-select :multiple="filter.multiple" class="select-primary "
                 size="large"
                 v-model="filter.value"
      >
        <el-option v-for="option in filter.options"
                   class="select-primary"
                   :value="getOptionValue(option,filter)"
                   :label="getOptionLabel(option,filter)"
                   :key="getOptionValue(option,filter)">
        </el-option>
      </el-select>


    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },

  props: ["filter"],
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getOptionLabel(option,filter){
      if(filter && filter.optionLabel){
        return option[filter.optionLabel];
      }
      return option.name;
    },
    getOptionValue(option,filter){
      if(filter && filter.optionValue){
        return option[filter.optionValue];
      }
      return option.id;
    },
  }
}
</script>

<style>
.select-filter{
  margin: 5px;
}
</style>
