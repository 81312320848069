<template>
    <div class="advance-search">

        <filter-organizer :key="componentKey" class="mt-4 px-4 row" id="element-container"  url=""
        :filters="currentFilter"></filter-organizer>

        <div class="modal-footer">
            <button @click="sendAdvanceSearch" class="btn btn-success">Search</button>
            <button @click="reset" ref="resetBtn" class="btn  btn-danger">Clear</button>
        </div>
    </div>
</template>

<script>
import FilterOrganizer from '../FilterShared/FilterOrganizer.vue';


export default {
  components: { FilterOrganizer },
    props: ["filters", 'url', 'title','id'],
    data() {
        return {
            currentFilter: [],
          componentKey: 0,
        }
    },
    watch: {
      filters: {
        handler: function(newValue) {
          this.prepareFilter(newValue);
        },
        deep: true
      }
    },
    mounted() {
      this.prepareFilter(this.filters);
    },
    methods: {
        prepareFilter: function(filters){
          this.currentFilter= [];
          for(let filter of filters){
            if(!filter.permession ){
              this.currentFilter.push(filter);
            }
          }
        },
        sendAdvanceSearch: function () {
            this.$emit('filter-data', this.currentFilter);
        },
        reset: function () {
            for(let filter of this.currentFilter){
                filter.value = null ;
            }
            this.$emit('filter-data', this.currentFilter);
        },

    }
}
</script>

