import './polyfills'
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents'
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives'
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from './components/SidebarPlugin'
import NotificationPlugin from './components/NotificationPlugin'
// Tabs plugin. Used on Panels page.
import VueTabs from 'vue-nav-tabs'

// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

// asset imports
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-nav-tabs/themes/vue-tabs.scss'
import './assets/sass/light-bootstrap-dashboard.scss'
import './assets/css/demo.css'
import  LoadingOverlay from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {Loading}  from 'element-ui';

export default {
  async install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(NotificationPlugin)
    Vue.use(VueTabs)
    Vue.use(Loading.directive)
    Vue.use(LoadingOverlay, {
      color: '#23CCEF',
      width: 100,
      height: 100,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999
    });
  }
}
