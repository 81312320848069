import DashboardLayout from "@/pages/layouts/DashboardLayout";

let rolesRoutes = {
  path: '/roles',
  component: DashboardLayout,
  redirect: '/roles/list',
  children: [
    {
      path: 'list',
      name: 'Role List',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/roles/RoleList'),
      meta: {
        permissionsCodes: ['roles/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Role',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/roles/RoleForm'),
      meta: {
        permissionsCodes: ['roles/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Role',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/roles/RoleForm'),
      meta: {
        permissionsCodes: ['roles/update'],
      }
    }
  ]
}

export default rolesRoutes;
