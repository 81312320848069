import DashboardLayout from "@/pages/layouts/DashboardLayout";

let pagesRoutes = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/list',
  children: [
    {
      path: 'list',
      name: 'Pages List',
      component: ()=>import(/* webpackChunkName: "pages" */  '@/pages/pages/PageList'),
      meta: {
        permissionsCodes: ['pages/index'],
      }
    },
  ]
}

export default pagesRoutes;
