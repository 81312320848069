import DashboardLayout from "@/pages/layouts/DashboardLayout";

let contactusRoutes = {
  path: '/contact-us',
  component: DashboardLayout,
  redirect: '/contact-us/list',
  children: [
    {
      path: 'list',
      name: 'Contact Messages List',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/general/contactus/ContactusList'),
      meta: {
        permissionsCodes: ['contact-us/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Contact Message Details',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/general/contactus/ContactUsView'),
      meta: {
        permissionsCodes: ['contact-us/get'],
      }
    },
  ]
}

export default contactusRoutes;
