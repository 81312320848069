<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <h5 style="text-align: center">Please write <b>Delete</b> in the text box to complete the process</h5>
    <fg-input type="text" v-model="input"></fg-input>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger"  @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" :disabled="checkDisableButton" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'delete-all',

  components: {
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      input: ""
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    }
  },

  computed: {
    checkDisableButton() {
      return !(this.input.toLowerCase() === 'delete');
    }
  },

  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm');
    }

  }

}
</script>
