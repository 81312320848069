var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Login")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? errors[0] : null,"hasSuccess":passed,"label":"Email address","name":"email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? errors[0]: null,"hasSuccess":passed,"name":"password","label":"Password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd ",attrs:{"type":"submit","disabled":_vm.submitting}},[_vm._v("Login ")]),_c('br'),_c('div',{staticClass:"forgot"},[_c('router-link',{staticClass:"card-category",attrs:{"to":"/forget-password"}},[_vm._v(" Forgot your password? ")])],1)])])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }